<template>
  <div class="container">
    <div class="header flex-between-start">
      <div class="handle">
        <div class="title">核销记录</div>
      </div>
      <div class="search">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="至"
          start-placeholder="核销开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"
        />
        <el-select v-model="status" placeholder="请选择状态" clearable>
          <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-input v-model="couponKey" type="text" placeholder="请输入活动名称 | 活动号" clearable></el-input>
        <el-input v-model="mobile" type="text" placeholder="请输入手机号" clearable></el-input>
        <el-button @click="pageEmit(1)">搜索</el-button>
      </div>
    </div>

    <div class="statistics" v-permission="['核销统计']">
      <el-tag effect="plain" size="large">
        订单金额：{{ statistics.total_order_at }}， 优惠券抵扣总额：{{ statistics.total_discount_at }}元，
        已承兑数量：{{ statistics.total_used_count }}， 已过期数量：{{ statistics.total_expire_count }}
      </el-tag>
    </div>

    <!-- 列表 -->
    <el-table :data="tableData.items" v-loading="loading" height="600">
      <el-table-column prop="mobile" label="用户手机号" width="120"></el-table-column>
      <el-table-column prop="" label="优惠券名称">
        <template #default="scope">
          {{ scope.row.coupon_activity?.name }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-tag size="small" :type="statusTag[scope.row.opera_tp]">{{ scope.row.opera_tp_cn }}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="" label="使用商家">
        <template #default="scope">
          {{ scope.row.business && scope.row.business.length ? scope.row.business.length : '未知商家' }}
        </template>
      </el-table-column> -->
      <el-table-column prop="mchnt_cd" label="商家号">
        <template #default="scope">
          {{ scope.row.mchnt_cd || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="pos_tmn" label="终端号">
        <template #default="scope">
          {{ scope.row.pos_tmn || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="order_at" label="订单金额" width="110"></el-table-column>
      <el-table-column prop="discount_at" label="优惠金额" width="100"></el-table-column>
      <el-table-column prop="" label="消费金额" width="110">
        <template #default="scope">
          {{ (Number(scope.row.order_at) - Number(scope.row.discount_at)).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column prop="trans_ts" label="消费时间" width="200"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <page
      @page-event="pageEmit"
      :current-page="pageParam.currentPage"
      :total="tableData.total"
      :page-size="tableData.count"
    ></page>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import commonHandle from '@/hooks/useCommon'
import permissionHandle from '@/hooks/usePermission'
import statisticsModel from '@/model/statistics'
import util from '@/lin/util/util'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'

export default {
  setup() {
    const time = ref([])
    const couponKey = ref()
    const mobile = ref()
    const status = ref()
    const statistics = ref({})
    const store = useStore()
    const statusList = ref([
      {
        id: '01',
        name: '已承兑',
      },
      {
        id: '06',
        name: '已过期',
      },
    ])
    const statusTag = {
      '01': 'success',
      '06': 'info',
    }

    const getData = async pageQuery => {
      if (mobile.value && !util.checkLength(mobile.value, [10, 11])) return ElMessage.warning('请输入完整的手机号码')
      if (couponKey.value && !util.checkLength(couponKey.value, 2)) return ElMessage.warning('活动名称或活动号至少输入2个字符')
      if (checkPermission('核销统计')) getStatistics()

      loading.value = true
      const res = await statisticsModel.getUseRecord({
        page: pageQuery?.page || pageParam.currentPage - 1,
        count: pageQuery?.count || pageParam.count,
        start: time.value ? time.value[0] : null,
        end: time.value ? time.value[1] : null,
        mobile: mobile.value || null,
        coupon_key: couponKey.value || null,
        status: status.value || null,
      })
      loading.value = false
      tableData.value = res
      return res
    }

    // 获取统计数据
    const getStatistics = async () => {
      const res = await statisticsModel.useStatistics({
        start: time.value ? time.value[0] : null,
        end: time.value ? time.value[1] : null,
        mobile: mobile.value || null,
        coupon_key: couponKey.value || null,
        status: status.value || null,
      })
      statistics.value = res
    }

    const { tableData, loading, pageParam, pageEmit } = commonHandle(getData)
    const { checkPermission } = permissionHandle(store)

    onMounted(() => {
      getData()
    })

    return {
      time,
      couponKey,
      mobile,
      statistics,
      status,
      statusTag,
      statusList,
      tableData,
      loading,
      pageParam,
      pageEmit,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';
</style>
